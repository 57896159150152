<template>
  <div>
    <common-header></common-header>
    <div class="order-type-box warp">
      <div class="type-fixed" :style="`top:${isWeiXin() ? 0 : 40}px`">
        <div class="btn" :class="datas.status == '' ? 'active' : ''" @click="changeTab(0, '')">全部</div>
        <div class="btn" :class="datas.status == 100 ? 'active' : ''" @click="changeTab(1, 100)">待支付</div>
        <div class="btn" :class="datas.status == 200 ? 'active' : ''" @click="changeTab(2, 200)">待发货</div>
        <div class="btn" :class="datas.status == 201 ? 'active' : ''" @click="changeTab(3, 201)">待收货</div>
        <div class="btn" :class="datas.status == 202 ? 'active' : ''" @click="changeTab(4, 202)">已完成</div>
        <div class="btn" :class="datas.status == 400 ? 'active' : ''" @click="changeTab(5, 400)">退款</div>
      </div>
    </div>

    <ul class="order-lists warp"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="loading"
    infinite-scroll-immediate-check="false"
    infinite-scroll-distance="10"
    >
      <li class="item" v-for="(item, index) in listObj.list" :key="index" @click="$router.push({path: '/account/orderdetails', query: {id: item.id}})">
        <div class="time-type">
          <div class="time">{{item.createTime}}</div>
          <div class="type">{{orderStatus(item.status)}}</div>
        </div>
        <!-- <div class="goods-list" @click="$router.push({path: '/account/orderdetails', query: {id: item.id}})">
          <div class="li" v-for="(item2, index2) in item.orderDetail" :key="index2">
            <img class="goods-img" :src="noImgSet(item2.image)" alt="">
            <div class="goods-info">
              <div class="name">{{item2.name}}</div>
              <div class="ms">{{item2.specName}}</div>
              <div class="price">
                <div class="num">x{{item2.quantity}}</div>
                <div class="money"><span class="unit">￥</span>{{item2.unitPrice}}</div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="commodity-ul">
          <div class="commodity-item" v-for="(item2, index2) in item.orderDetail" :key="index2">
            <img class="commodity-img" :src="noImgSet(item2.image)" alt="">
            <div class="right">
              <div class="commodity-details">
                <h4 class="name">{{item2.name}}</h4>
                <p class="commodity-ms">{{item2.specName}}{{item2.seckillId ? '(秒杀)' : ''}}</p>
              </div>
              <div class="commodity-num-box">
                <div class="num">x{{item2.quantity}}</div>
                <div class="money">
                  <span class="deposit" v-if="item2.deposit > 0">(含押金{{item2.deposit}}元)</span>
                  <span class="unit">￥</span>{{item2.price + item2.deposit}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="total-box" v-if="item.couponAmount || item.deductBalance > 0">
          <div class="item" v-if="item.couponAmount">
            <div class="il">优惠券</div>
            <div class="ir"><span class="unit">-￥</span>{{item.couponAmount}}</div>
          </div>
          <div class="item" v-if="item.deductBalance">
            <div class="il">余额抵扣</div>
            <div class="ir"><span class="unit">-￥</span>{{item.deductBalance}}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="money">
            <div class="total">
              <!-- <div class="l" v-if="item.originTotalPrice != item.realTotalPrice">
                总价:<span class="del">￥{{item.originTotalPrice}}</span>
              </div> -->
              <div>
                实付款<span class="actual"><span class="unit">￥</span><b class="je">{{item.realTotalPrice}}</b></span>
              </div>
            </div>
          </div>
        </div>
        <div class="commodity-btn-box">
          <div class="btn hui" v-if="item.status == 100 && item.payStatus != 1" @click.stop="cancelOrder(item.orderCode)">取消订单</div>
          <div class="btn hui" v-if="item.status > 100" @click.stop="onMoreOrder(item.id)">再来一单</div>
          <div class="btn hui" v-if="item.status == 200" @click.stop="applyRefund(item.orderCode)">申请退款</div>
          <div class="btn jb-r-l" v-if="item.status == 100 && item.payStatus != 1" @click.stop="mallPay(item.orderCode, item.realTotalPrice)">立即支付</div>
          <div class="btn jb-r-l" v-if="item.status == 201" @click.stop="confirmReceipt(item.orderCode)">确认收货</div>
        </div>
      </li>
    </ul>

    <!-- 支付是否成功start -->
    <PayPop :orderCode="orderCode" :payTypePop="payTypePop"></PayPop>
    <!-- 支付是否成功start -->
    
    <no-data v-if="nodata"></no-data>
    <load-completion v-if="nomore && listObj.list.length > 4"></load-completion>

  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl, isWeiXin, noImgSet, onBridgeReadyStart} from "@/utils/common";
import PayPop from '@/components/global/PayPop'
import { orderStatus } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.orderStatus = (e) => orderStatus(e);
    this.noImgSet = (e) => noImgSet(e);
    this.isWeiXin = () => isWeiXin();
    return {
      tabIndex: 0,
      loading: false,
      nodata: false,
      nomore: false,
      payTypePop: false,
      datas:{
        pageNum: 1,
        pageSize: 30,
        // 100=已下单，200=待发货（已支付），201=已发货，202=确认收货，300=已取消，400=已完成，500已退款
        status: "",
      },
      orderCode: "",
      listObj: "",
    }
  },
  components:{
    'PayPop': PayPop
  },
  mounted() {
    let datas = this.datas;
    if (this.$route.query.status) {
      datas.status = this.$route.query.status;
      this.status = datas;
    }
    this.init(datas);

  },
  methods: {
    // 再来一单
    onMoreOrder(id) {
      this.getOrderInfo({
        orderId: id,
      }).then((res)=> {
        // this.$router.push('/shopping/car')
        this.$toast('已成功添加到购物车');
      })
    },
    // 初始化
    init(obj, add) {
      this.$Indicator.open({
        text: '加载中...',
        spinnerType: 'snake',
      });
      this.getMyOrder(obj).then((res)=> {
        if (add) {
          let { listObj } = this;
          listObj.list = listObj.list.concat(res.data.list);
          this.listObj = listObj;
        } else {
          this.listObj = res.data;
        }
        this.nodata = this.listObj.list.length > 0 ? false : true;
        this.$Indicator.close();
      })
    },
    // 支付
    mallPay(orderCode, realTotalPrice) {
      this.$Indicator.open({
        text: '检查支付环境...',
        spinnerType: 'snake',
      });
      this.postOrderLink({
        orderCode: orderCode,
        paySource: "WECHAT"
      }).then((res2)=> {
        if(isWeiXin()) {
          onBridgeReadyStart(JSON.parse(res2.data.payParam), (res3)=> {
            this.$router.push({
              path: '/shopping/payment', 
              query: {orderCode: orderCode, price: realTotalPrice}
            })
          }, ()=> {
            this.$toast("取消支付");
          });
        }
        this.$Indicator.close();
      })
    },
    // 确认收货
    confirmReceipt(orderCode) {
      this.$MessageBox({
        title: '确认收到货了吗?',
        message: '为了保证您的售后权益,请收到商品检测无误后再确认收货',
        showCancelButton: true,
        confirmButtonText: "确认收货",
      }).then((res)=> {
        if (res == 'confirm') {
          this.PutConfirmreceipt({
            orderCode: orderCode,
          }).then((res)=> {
            this.init(this.datas);
          })
        }
      })
    },
    // 申请退款
    applyRefund(orderCode) {
      this.$MessageBox({
        title: '温馨提示',
        message: '是否申请退款?',
        showCancelButton: true
      }).then((e)=> {
        if (e == 'confirm') {
          this.PutApplyRefund({
            orderCode: orderCode,
          }).then((res)=> {
            this.$toast('申请成功');
            setTimeout(()=> {
              this.init(this.datas);
            },1500)
          })
        }
      })
      
    },
    // 切换
    changeTab(e, status){
      this.tabIndex = e;
      let { datas } = this;
      datas.pageNum = 1;
      datas.status = status;
      this.datas = datas;
      this.nomore = false;
      this.loading = false;
      this.listObj = [];
      this.init(datas);
    },
    // 加载下一页
    loadMore() {
      this.loading = true;
      let { listObj, datas} = this;
      datas.pageNum = datas.pageNum + 1;
      this.datas = datas;
      if (datas.pageNum > listObj.pages) {
        console.log("到底了")
        this.nomore = true;
      } else {
        this.loading = false;
        this.init(datas, 'add');
      }
    },
    // 取消订单
    cancelOrder(orderCode) {
      this.$MessageBox({
        title: '温馨提示',
        message: '是否取消订单?',
        showCancelButton: true
      }).then((e)=> {
        if (e == 'confirm') {
          this.PutCancelOrder({
            orderCode: orderCode,
          }).then((res)=> {
            this.init(this.datas);
          })
        }
      })
    },
    ...mapActions('account', [
      "getMyOrder",
      "PutConfirmreceipt",
      "PutCancelOrder",
      'PutApplyRefund',
    ]),
    ...mapActions("shopping", [
      "postOrderLink",
      "getOrderInfo",
    ]),
  },
}
</script>
<style lang='less' scoped>
.total-box{
  border-top: 1px solid #f7f7f7;
  padding-top: .2rem;
  .unit{
    font-size: .24rem;
  }
  .il{
    font-size: .26rem;
  }
  .ir{
    font-size: .32rem;
    display: flex;
    align-items: flex-end;
    line-height: 1;
  }
  .copus-xx{
    display: flex;
    align-items: center;
    .ir{
      margin-right: .1rem;
    }
  }
  .item{
    font-size: .28rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    height: .5rem;
    align-items: center;
    padding: 0 !important;
  }
  .price{
    margin-top: 0;
    margin-left: .1rem;
  }
}
.deposit{
  font-size: .24rem;
  color: #bfbfbf;
  margin-right: .15rem;
}
.bottom{
  height: .9rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  .btn{
    width: 2.15rem;
    height: .65rem;
    border-radius: .32rem;
    color: #fff;
    text-align: center;
    line-height: .65rem;
    font-weight: 700;
  }
  .del{
    text-decoration: line-through;
  }
  .unit{
    font-size: .24rem;
  }
  .actual{
    color: #ff7241;
    margin-left: .12rem;
    .je{
      font-size: .32rem;
      font-weight: normal;
    }
  }
  .total{
    display: flex;
    font-size: .26rem;
    color: #727272;
    .l{
      margin-right: .2rem;
    }
  }
}
.goods-list{
  background: #fff;
  margin-top: .2rem;
  border-radius: .1rem;
  overflow: hidden;
  .li{
    display: flex;
    padding-bottom: .3rem;
    .goods-info{
      flex: 1;
      .name{
        color: #262626;
        font-size: .3rem;
        font-weight: 700;
      }
      .ms{
        font-size: .26rem;
        color: #bfbfbf;
        margin-top: .05rem;
      }
    }
  }
  .goods-img{
    width: 1rem;
    height: 1rem;
    border-radius: .05rem;
    margin-right: .3rem;
  }
}
.price{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .4rem;
  .num{
    font-size: .26rem;
    color: #727272;
  }
  .unit{
    font-size: .24rem;
  }
  .money{
    font-size: .32rem;
    color: #ff7241;
    font-weight: 700;
  }
}
.order-lists{
  margin-top: .2rem;
  .item{
    padding-top: .46rem;
    padding-left: .36rem;
    padding-right: .36rem;
    background: #fff;
    border-radius: .1rem;
    margin-bottom: .2rem;
  }
}
.time-type{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time{
    font-size: .24rem;
    color: #bfbfbf;
  }
  .type{
    font-size: .24rem;
    color: #ff7241;
  }
}
.order-type-box{
  .type-fixed{
    position: fixed;
    left: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 1.13rem;
    align-items: center;
    line-height: 1.13rem;
    font-size: .3rem;
    color: #262626;
    padding: 0 .2rem;
    box-shadow: 0 0 .1rem #e8e8e8;
  }
  height: 1.13rem;
  .active{
    color: #ff7241;
    font-weight: 700;
    border-bottom: .04rem solid #ff7241;
  }
}
</style>